<app-page-layout [pageTitle]="'coupon.coupons' | translate">
    <ng-container buttons>
        <app-aw-filter-button [style]="isMobile ? '' : 'margin-right: 15px'" [popupAnchor]="isMobile ? 'left' : 'right'" [filterOptions]="filterOptions" (filterChanged)="catchFilterChanged($event)" />
        <button class="btn btn-primary btn-tiny" (click)="navigateToCouponCreation()">
            <img class="plus-icon-on-btn" src="../../../../assets/icons/plus-for-create.svg" alt="plus icon" />
            {{ "coupon.create_coupon" | translate }}
        </button>
    </ng-container>
    <ng-container body>
        <div *ngIf="initLoading && !isMobile" class="loading-height">
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>

        <div class="cont row table-wrapper">
            <app-aw-table
                *ngIf="!isMobile"
                [tableHeaders]="tableHeaderOptions"
                [emptytext]="'coupon.no_coupons' | translate"
                [pageNumber]="params.pageNumber"
                [pageSize]="params.pageSize"
                [pageSizeOptions]="pageSizeOptions"
                [totalItems]="params.total"
                [hasExpandableOrClickableRows]="true"
                (handlePageChange)="handlePage($event)"
                (sortBy)="catchSortChanged($event)"
                [loadingData]="loadingCoupons"
                [initalSort]="this.params.sortBy"
            >
                <ng-container table-body>
                    <div class="row-container" *ngFor="let coupon of coupons">
                        <row style="width: 100%; cursor: pointer" (click)="navigateToCouponEdit(coupon)">
                            <cell style="width: 10%" [title]="coupon.name"
                                ><span>{{ coupon.name }}</span>
                            </cell>
                            <cell style="width: 25%" [attr.title]="getTerms(coupon)">
                                <span>{{ shortenTerms(getTerms(coupon)) }}</span>
                            </cell>
                            <cell style="width: 15%" [title]="'coupon.dis_type.' + coupon.distribute_type | translate">
                                <span>{{ "coupon.dis_type." + coupon.distribute_type | translate }}</span>
                            </cell>
                            <cell style="width: 10%; text-align: right" [title]="coupon.global ? ('coupon.global' | translate) : (coupon.locations?.length ?? '-')">
                                <span style="width: 100%">{{ coupon.global ? ("coupon.global" | translate) : (coupon.locations?.length ?? "-") }}</span>
                            </cell>
                            <cell style="width: 10%; text-align: right" [title]="coupon.entries ? (coupon.entries.users.limit ? coupon.entries.users.count + '/' + coupon.entries.users.limit : coupon.entries.users.count) : ''">
                                <span style="width: 100%">{{ coupon.entries ? (coupon.entries.users.limit ? coupon.entries.users.count + "/" + coupon.entries.users.limit : coupon.entries.users.count) : "" }}</span>
                            </cell>
                            <cell style="width: 10%; text-align: right" [title]="coupon.redemptions">
                                <span style="width: 100%">{{ coupon.redemptions ? coupon.redemptions : 0 }}</span>
                            </cell>
                            <cell style="width: 10%; text-align: right">
                                <span style="width: 100%">{{ coupon.duration?.to ? helperService.getLocalizedDate(coupon.duration.to) : "-" }}</span>
                            </cell>
                            <cell style="width: 10%; text-align: right" [title]="coupon.created">
                                <span style="width: 100%">{{ helperService.createdLabelForLists(coupon.created) }}</span>
                            </cell>
                        </row>
                    </div>
                </ng-container>
            </app-aw-table>
        </div>

        <!-- Mobile coupon view-->
        @if (isMobile) {
            <div *ngIf="loadingCoupons && isMobile" class="loading-height">
                <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
            </div>
            <div class="coupon-mobile" *ngIf="!loadingCoupons && isMobile">
                <div *ngIf="coupons.length">
                    <div class="d-flex box outer-box mb-1 mobile-coupon" *ngFor="let coupon of coupons | paginate: params.pageSize : params.pageNumber : true">
                        <div (click)="navigateToCouponEdit(coupon)">
                            <div class="mobile-coupon-header mt-2 d-flex">
                                {{ coupon.name }}
                            </div>
                            <div class="mobile-coupon-body">
                                <div class="mobile-coupon-terms">
                                    {{ shortenTerms(getTerms(coupon)) }}
                                </div>
                                <div class="mobile-coupon-created d-flex">
                                    <label>{{ "coupon.created" | translate }}</label>
                                    <p class="coupon-created-date">
                                        {{ helperService.getLocalizedDate(coupon.creation_date) }}
                                        -
                                        {{ helperService.getLocalizedTime(coupon.creation_date) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex box outer-box mb-1 mobile-coupon" *ngIf="!coupons.length && !loadingCoupons">
                    <div class="card-item no-items-in-list">
                        <table style="width: 100%">
                            <div style="display: table-row-group">
                                <tr>
                                    <td class="d-flex justify-content-around align-items-center">
                                        <div>
                                            {{ "coupon.no_coupons" | translate }}
                                        </div>
                                    </td>
                                </tr>
                            </div>
                        </table>
                    </div>
                </div>
                <mat-paginator id="matpageMobile" class="pagination mobile-paginator" (page)="handlePage($event)" [length]="params.total" [pageSize]="params.pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="params.pageNumber">
                </mat-paginator>
            </div>
        }
    </ng-container>
</app-page-layout>
