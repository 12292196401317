import { SnapshotAction } from '@angular/fire/compat/database';
import { NgFor, NgIf } from '@angular/common';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BillingCardComponent } from '@components/settings/billing/billing-card-modal/billing-card.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import hash from 'object-hash';
import { BillingService } from '../../../../services/billing/billing.service';
import { HelperService } from '../../../../services/helper/helper.service';
import { LoadingComponent } from '../../../loading/loading.component';
import { AwCheckboxComponent } from '../../../misc/aw-checkbox/aw-checkbox.component';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';
import { environment } from '../../../../../environments/environment';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { Price } from '@shared_models/billing/price';
import { FilterSortParams } from '@shared_models/search-params/FilterSortParams';
import { BillingDetails, BillingPlan, CardRequireAction, ShallowBillingUnit } from '@shared_models/billing/billing';

@Component({
    selector: 'app-billing-subscription-modal',
    templateUrl: './billing-subscription-modal.component.html',
    styleUrls: ['./billing-subscription-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, NgIf, FormsModule, ReactiveFormsModule, MatRadioGroup, MatRadioButton, NgFor, AwCheckboxComponent, LoadingComponent, TranslateModule, BillingCardComponent]
})
export class BillingSubscriptionModalComponent implements OnInit {
    @ViewChild(BillingCardComponent) billingCardComponent: BillingCardComponent;
    @Output() subscriptionCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() selectedUnits: string[] = [];
    @Input() unselectedUnits: string[] = [];
    @Input() selectAll: boolean;
    @Input() billingDetails: BillingDetails;
    @Input() paramsForInactive: FilterSortParams;

    unitsToBill: ShallowBillingUnit[] = [];
    user: DashboardUser = this.helperService.getUser();
    billedBy: 'card' | 'invoice' | 'not_set';
    initLoad: boolean = true;
    hasDiscount: boolean = false;
    selectedPlan: BillingPlan;
    billingForm: UntypedFormGroup;
    invoicePrices: Price[] = [];
    cardPrices: Price[] = [];
    formSubmitted: boolean = false;
    createLoading: boolean = false;
    insuranceInfoHover: boolean;
    eanFingerprint: string;
    loadingEanResponse = false;
    useEan = false;
    eanNumberIsValid: boolean;
    modalref: NgbModalRef;
    currency: string;

    constructor(
        private billingService: BillingService,
        private formBuilder: UntypedFormBuilder,
        protected helperService: HelperService,
        private translate: TranslateService,
        private modalService: NgbModal
    ) {}

    //#region init functions
    async ngOnInit() {
        this.createBillingForm();
        await Promise.all([this.getUnits(), this.getPrices()]);
        this.initLoad = false;
    }

    async getUnits() {
        await this.billingService.getBillingUnitsForSubscriptionInit(this.paramsForInactive, { selectAll: this.selectAll, selectedUnits: this.selectedUnits, unselectedUnits: this.unselectedUnits }).then(res => {
            this.unitsToBill = res;
            for (const unit of this.unitsToBill) {
                if (unit.discount) {
                    this.hasDiscount = true;
                    break;
                }
            }
        });
    }

    createBillingForm(): void {
        this.billingForm = this.formBuilder.group({
            ean_number: new UntypedFormControl(null),
            economic_contact_name: new UntypedFormControl(null),
            order_reference: new UntypedFormControl(null, this.billingDetails.billedBy === 'invoice' ? [Validators.required, Validators.maxLength(15)] : null), // reference is hidden and not required if card is selected
            insurance: new UntypedFormControl(true),
            plan: new UntypedFormControl(null, [Validators.required]),
            paymentMethod: new UntypedFormControl(this.user.settings.stripe_region !== 'eu' ? 'card' : this.billingDetails.billedBy === 'not_set' ? 'invoice' : this.billingDetails.billedBy, [Validators.required])
        });
    }

    paymentMethodChange() {
        this.order_reference.setErrors(null);
        this.order_reference.setValidators(this.paymentMethod.value === 'invoice' ? [Validators.required, Validators.maxLength(15)] : null);
    }

    getPrices(): void {
        const readPricesSub = this.billingService
            .readPrices(this.user.settings.stripe_region, this.billingDetails.shallowAccountDetails.address.country === 'AD' ? 'AD' : this.user.settings.country)
            .snapshotChanges()
            .subscribe((pricesSnap: SnapshotAction<Record<string, Price>>) => {
                readPricesSub.unsubscribe();
                if (pricesSnap.payload.exists()) {
                    const prices: Record<string, Price> = pricesSnap.payload.val();
                    this.invoicePrices = [];
                    this.cardPrices = [];

                    for (const priceProp in prices) {
                        const price: Price = prices[priceProp];
                        this.currency = price.currency;
                        price.id = priceProp; // setting the id for the price on each object
                        if (price.active) {
                            this.invoicePrices.push(price);
                            if (price.interval <= 3 * 12) this.cardPrices.push(price); // max is 3 years
                        }
                    }
                    // sort so that the longest subscription period is the first element in the array (i.e. the cheapest per month)
                    this.invoicePrices.sort((a, b) => (a.interval < b.interval ? 1 : -1));
                    this.cardPrices.sort((a, b) => (a.interval < b.interval ? 1 : -1));
                } else {
                    this.helperService.defaultHtmlToast(`${this.translate.instant('billing.missing_sub_price')}:${this.currency}`, this.translate.instant('billing.please_contact'), 'Info');
                }
            });
    }
    //#endregion

    //#region EAN-stuff
    handleEanStateChange(event: { value: boolean }): void {
        this.billingForm.get('ean_number').setErrors(null);
        this.eanFingerprint = null;
        this.useEan = typeof event.value === 'boolean' ? event.value : !!parseInt(event.value);
        this.ean_number.setValidators(this.useEan ? [Validators.required, Validators.maxLength(13)] : null);
        this.economic_contact_name.setValidators(this.useEan ? [Validators.required] : null);
        this.ean_number.updateValueAndValidity();
        this.economic_contact_name.updateValueAndValidity();

        if (!this.useEan)
            this.billingForm.patchValue({
                ean_number: null,
                economic_contact_name: null
            });
    }
    async validateEanNumber(): Promise<void> {
        this.billingForm.get('ean_number').setErrors({ pending: true });
        this.eanNumberIsValid = false;
        this.loadingEanResponse = true;
        this.eanFingerprint = hash.keys(`${this.ean_number.value}${dayjs()}`);
        const { isValid, fingerprint } = await this.billingService.validateEan(this.ean_number.value, this.eanFingerprint);
        if (this.eanFingerprint === fingerprint) {
            this.loadingEanResponse = false;
            this.eanNumberIsValid = isValid;
            this.ean_number.setErrors(isValid ? null : { invalid: true });
        }
    }
    clearPending() {
        this.billingForm.get('ean_number').setErrors(null);
    }
    //#endregion

    //#region plan-selection
    selectPlan(price: Price, billedBy: 'card' | 'invoice') {
        this.selectedPlan = { price, billed_by: billedBy };
        this.currency = price.currency;
        this.plan.setValue(this.selectedPlan);
    }

    getIntervalForPlan(interval: number): string {
        // interval can be null if no plan is yet picked
        if (!interval) return `${this.translate.instant('billing.choose_a_plan')}`;
        if (interval === 1) return `${this.translate.instant('billing.monthly')}`;
        if (interval > 1) return `${this.translate.instant('billing.billed_every')} ${interval} ${this.translate.instant('billing.months')}`;
    }
    getPriceLabel(result: string): { firstPart: string; secondPart: string } {
        const firstPart: string = result.substring(0, result.length - 3);
        const secondPart: string = result.substring(result.length - 3, result.length);
        return { firstPart, secondPart };
    }

    getPriceLabelForPlan(unitAmount: number, interval: number): { firstPart: string; secondPart: string } {
        const result: string = this.helperService.localizeNumber(unitAmount / 100 / interval);
        return this.getPriceLabel(result);
    }

    getAnnualSavingsLabel(price: Price, priceList: 'card' | 'invoice'): string {
        let str = this.translate.instant('billing.save');
        const highestPrice: Price = this.getHighestPrice(priceList === 'card' ? this.cardPrices : this.invoicePrices);
        const currentAnnualCost = (price.unit_amount * 12) / price.interval;
        const highestAnnualCost = (highestPrice.unit_amount * 12) / highestPrice.interval;
        const savings = (highestAnnualCost - currentAnnualCost) / 100;
        str += ' ' + this.helperService.roundToTwoDecimals(savings).toFixed(2) + ' ';
        str += this.currency.toUpperCase() + '/' + this.translate.instant('misc.year');
        return str;
    }

    getHighestPrice(prices: Price[]): Price {
        return prices.sort((a, b) => {
            return (a.unit_amount * 12) / a.interval - (b.unit_amount * 12) / b.interval;
        })[prices.length - 1];
    }
    //#endregion

    //#region billing-summary
    getIntervalLabel(): string {
        // interval can be null if no plan is yet picked
        const interval = this.selectedPlan ? this.selectedPlan.price.interval : null;
        if (!interval) return `${this.translate.instant('billing.choose_a_plan')}`;
        if (interval === 1) return `${this.translate.instant('billing.monthly')}`;
        if (interval > 1) return `${this.translate.instant('billing.billed_every')} ${interval} ${this.translate.instant('billing.months')}`;
    }
    getPricePerUnitLabel(): string {
        const pricePerUnit = this.selectedPlan ? this.selectedPlan.price.unit_amount / this.selectedPlan.price.interval : 0;
        const result: string = this.helperService.localizeNumber(pricePerUnit / 100);
        return `${result} ${this.currency.toUpperCase()}`;
    }
    getInsurancePriceLabel(): string {        
        if (!this.selectedPlan || this.insurance.value === false) return `0 ${this.currency.toUpperCase()}`;
        const amount = ((this.selectedPlan ? this.selectedPlan.price.unit_amount : 0) * 0.2 * this.unitsToBill.length) / 100;
        const result: string = this.helperService.localizeNumber(amount);
        return `${result} ${this.currency.toUpperCase()}`;
    }
    getSubTotalLabel(): string {
        // if (!this.selectedPlan) return `${} ${this.currency.toUpperCase()}`;
        let amount = (this.selectedPlan ? this.selectedPlan.price.unit_amount : 0) * this.unitsToBill.length;
        if (this.insurance.value) amount += amount * 0.2;
        const result: string = this.helperService.localizeNumber(amount / 100);
        return `${result} ${this.currency.toUpperCase()}`;
    }
    getDiscountLabels(): { count: number; percent: number; value: string }[] {
        const discounts = this.getDiscounts();
        discounts.sort((a, b) => (a.discount < b.discount ? 1 : -1));
        const result: { count: number; percent: number; value: string }[] = [];
        for (const discount of discounts) {
            const value = (discount.amount > 0 ? '-' : '') + this.helperService.localizeNumber(discount.amount / 100) + ' ' + this.currency.toUpperCase();
            result.push({ count: discount.count, percent: this.helperService.roundToTwoDecimals(discount.discount * 100), value });
        }
        return result;
    }
    getVatAmount(): string {
        const vatAmount = this.getTotalAmount() * (this.billingDetails.vatZone.vat / 100);
        const result: string = this.helperService.localizeNumber(vatAmount / 100);
        return `${result} ${this.currency.toUpperCase()}`;
    }

    getTotalAmountlabel(): string {
        const amount = this.getTotalAmount() * (1 + this.billingDetails.vatZone.vat / 100);
        const result: string = this.helperService.localizeNumber(amount / 100);
        return `${result} ${this.currency.toUpperCase()}`;
    }
    getDiscounts(): { discount: number; count: number; amount: number }[] {
        const discounts: Record<string, { count: number; percent: number; amount: number }> = {};
        for (const unit of this.unitsToBill) {
            if (unit.discount > 0) {
                if (!discounts[unit.discount]) discounts[unit.discount] = { count: 0, percent: unit.discount, amount: 0 };
                discounts[unit.discount].count += 1;
                if (this.selectedPlan) discounts[unit.discount].amount += this.selectedPlan.price.unit_amount * unit.discount * (this.insurance.value ? 1.2 : 1);
            }
        }
        const result: { discount: number; count: number; amount: number }[] = [];
        for (const key in discounts) {
            result.push({ discount: discounts[key].percent, count: discounts[key].count, amount: discounts[key].amount });
        }
        return result;
    }
    getTotalAmount(): number {
        if (!this.selectedPlan) return 0;
        let amount = this.selectedPlan.price.unit_amount * this.unitsToBill.length;
        if (this.insurance.value) amount += amount * 0.2;
        const discounts = this.getDiscounts();
        for (const discount of discounts) {
            amount -= discount.amount;
        }
        return amount;
    }
    //#endregion

    async createSubscription(): Promise<void> {
        this.order_reference.setValidators(this.paymentMethod.value === 'invoice' ? [Validators.required, Validators.maxLength(15)] : null);
        this.formSubmitted = true;
        if (!this.billingForm.valid) {
            this.helperService.defaultHtmlToast(this.translate.instant('billing.form_invalid'), this.translate.instant('billing.check_fields'), 'Warning');
            return;
        }
        this.createLoading = true;

        //#region card-settings
        if (this.selectedPlan.billed_by === 'card') {
            if (!this.billingCardComponent.card || this.billingCardComponent.updateCard) {
                this.billingDetails.card = await this.billingCardComponent.saveCard().catch(error => {
                    this.helperService.defaultHtmlToast(this.translate.instant('billing.card_failed'), error.message, 'Error');
                    this.createLoading = false;
                    throw error;
                });
            }
            console.log('card details:', this.billingDetails.card);
            this.selectedPlan.cardId = this.billingDetails.card!.id;
        }
        //#endregion
        if (this.useEan)
            this.selectedPlan.ean = {
                number: this.ean_number.value,
                contactName: this.economic_contact_name.value
            };

        const serials = this.unitsToBill.map(unit => unit.serial);
        await this.billingService
            .createSubscription(this.selectedPlan, serials, this.order_reference.value, this.insurance.value)
            .then(async res => {
                if (res.requiresAction!) await this.handleConfirm3DSecure(res);
                else this.helperService.defaultHtmlToast(this.translate.instant('billing.setup_complete'), this.translate.instant(`billing.invoice_sent`), 'Success');
                this.subscriptionCreated.emit(true);
                this.modalService.dismissAll('Cancel');
            })
            .catch(() => {
                this.helperService.defaultHtmlToast(this.translate.instant('billing.payment_failed'), this.translate.instant('billing.try_again'), 'Error');
            });
        this.createLoading = false;
    }

    private async handleConfirm3DSecure(action: CardRequireAction): Promise<void> {
        const stripeRegion = this.user.settings.stripe_region;
        const stripeKey: string = environment[`stripePublicKey${stripeRegion.toUpperCase()}`];
        const stripe: Stripe = await loadStripe(stripeKey); // Initialize Stripe with your publishable key
        const params = action.params;
        await stripe
            .confirmCardPayment(params.clientSecret)
            .then(async result => {
                if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
                    await this.billingService.completeCardSubscription(params.pending3DSecureDocId);
                    this.helperService.defaultHtmlToast(this.translate.instant('billing.setup_complete'), this.translate.instant('billing.card_success_charged'), 'Success');
                }
            })
            .catch(error => {
                if (error.code == 'card_declined' && error.decline_code == 'insufficient_funds')
                    this.helperService.defaultHtmlToast(this.translate.instant('billing.payment_failed'), this.translate.instant('billing.insufficient_funds'), 'Error');
                else this.helperService.defaultHtmlToast(this.translate.instant('billing.payment_failed'), this.translate.instant('billing.try_again'), 'Error');
                throw error;
            });
    }

    get plan() {
        return this.billingForm.get('plan');
    }
    get paymentMethod() {
        return this.billingForm.get('paymentMethod');
    }
    get ean_number() {
        return this.billingForm.get('ean_number');
    }
    get economic_contact_name() {
        return this.billingForm.get('economic_contact_name');
    }
    get order_reference() {
        return this.billingForm.get('order_reference');
    }
    get insurance() {
        return this.billingForm.get('insurance');
    }
}
