import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from '../../services/customer/customer.service';
import { ToastrService } from 'ngx-toastr';
import { countries } from 'shared_data/countries';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { HelperService } from 'src/app/services/helper/helper.service';
import { CreateSubCustomerParams } from 'shared_models/sub-customer';
import { Title } from '@angular/platform-browser';
import { CountryDetails } from 'shared_models/country-details';
import { BusinessType, StripeRegions } from 'shared_models/stripe';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf, NgFor } from '@angular/common';
import { createSubCustomerSchema } from 'shared_zod/forms/operator/createSubCustomer.zod';
import { CreateSubCustomerForm } from '../misc/aw-form-creator/form-models/create-subcustomer';
import { CustomControl, RadioGroupControl, SelectControl, TextControl } from 'src/app/components/misc/aw-form-creator/aw-forms';
import { AwCheckboxComponent } from '@components/misc/aw-checkbox/aw-checkbox.component';
import { AwFormCreatorComponent } from '@components/misc/aw-form-creator/aw-form-creator.component';

@Component({
    selector: 'app-customers-create',
    templateUrl: './customers-create.component.html',
    styleUrls: ['./customers-create.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, LoadingComponent, TranslateModule, AwCheckboxComponent, AwFormCreatorComponent]
})
export class CustomersCreateComponent implements OnInit {
    formSubmitted = false;
    countries: CountryDetails[] = Object.values(countries);
    showLoading = false;
    createAccountBtnLabel = 'Create an account';
    supportPhone: {
        phoneNumber: string;
        telLink: string;
    } = environment.supportPhone;
    isNewSubCustomer: boolean;
    user: DashboardUser;
    pickedRegion: StripeRegions = StripeRegions.EU;
    euTermsOfUse: string = environment.euTermsOfUse;
    usTermsOfUse: string = environment.usTermsOfUse;
    gdprPolicy: string = environment.gdprPolicy;
    gdprChecked: boolean;
    termsChecked: boolean;

    subCustomerForm: CreateSubCustomerForm;
    schema: typeof createSubCustomerSchema = createSubCustomerSchema;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private titleService: Title,
        private customerService: CustomerService,
        private toast: ToastrService,
        protected helperService: HelperService
    ) {}

    ngOnInit() {
        this.titleService.setTitle('Create customer');
        const user = this.helperService.getUser();
        this.user = user;
        this.pickedRegion = this.user.settings.stripe_region;

        this.countries = this.countries
            .filter(function (countryItem) {
                return countryItem.platform === user.settings.stripe_region;
            })
            .sort(function (a, b) {
                // Assuming "country" is a string property, adjust the comparison accordingly
                return a.country.localeCompare(b.country);
            });

        this.subCustomerForm = {
            business_type: new RadioGroupControl({
                value: null,
                label: 'customers-create.choose_business_type',
                required: false,
                order: 1,
                disabled: true,
                options: [
                    { key: BusinessType.COMPANY, value: this.translate.instant('customers-create.company_partnership') },
                    { key: BusinessType.INDIVIDUAL, value: this.translate.instant('customers-create.individual_person') }
                ],
                showControl: false
            }),
            country: new SelectControl({
                value: null,
                label: 'misc.country',
                placeholder: 'sign_up.choose_country',
                required: true,
                order: 2,
                options: this.countries.map(country => ({ key: country.country, value: country.country })) || [],
                events: {
                    change: (e: Event) => {
                        const value = this.subCustomerForm.country.value;
                        const country = this.countries.find(country => country.country === value);
                        if (country && country.code === 'DK') {
                            this.businessType.showControl = true;
                            this.businessType.enable();
                            this.businessType.setValue(null);
                        } else {
                            this.businessType.showControl = false;
                            this.businessType.setValue(BusinessType.COMPANY);
                        }
                    }
                }
            }),
            email: new TextControl({
                value: null,
                label: 'misc.email',
                required: true,
                order: 3,
                type: 'email'
            }),
            terms_and_conditions: new CustomControl({
                value: false,
                label: 'customers-create.terms_and_conditions',
                required: true,
                order: 4
            }),
            gdpr_policy: new CustomControl({
                value: false,
                label: 'customers-create.gdpr_policy',
                required: true,
                order: 5
            })
        };
    }

    get email() {
        return this.subCustomerForm.email;
    }
    get country() {
        return this.subCustomerForm.country;
    }
    get businessType() {
        return this.subCustomerForm.business_type;
    }
    get termsAndConditions() {
        return this.subCustomerForm.terms_and_conditions;
    }

    get gdpr() {
        return this.subCustomerForm.gdpr_policy;
    }

    createCustomer() {
        this.formSubmitted = true;
        this.showLoading = true;
        this.createAccountBtnLabel = this.translate.instant('customers-create.wait');

        const emailLowerCased = this.email.value.replace(/\s/g, '').toLowerCase();
        const createParams: CreateSubCustomerParams = {
            email: emailLowerCased,
            business_type: this.businessType.value,
            country: this.countries.find(country => country.country === this.country.value).code
        };

        this.customerService
            .createSubCustomer(createParams)
            .then(() => {
                this.showLoading = false;
                this.toast.success(this.translate.instant('customers-create.succes_created'), this.translate.instant('misc.success'));
                this.router.navigate(['/operator/customers']);
            })
            .catch((httpResponseError: HttpErrorResponse) => {
                const { error } = httpResponseError.error;
                this.toast.info(this.translate.instant(error.showToast ? error.showToast.description : 'customers-create.something_wrong'), this.translate.instant('misc.error'), { timeOut: 15000 });
                this.showLoading = false;
            });
    }

    toggleConsent(isChecked: boolean, typeOfConsent: string) {
        if (typeOfConsent == 'terms' && isChecked) {
            this.subCustomerForm.terms_and_conditions.setValue(true);
        } else if (typeOfConsent == 'terms' && !isChecked) {
            this.subCustomerForm.terms_and_conditions.setValue(false);
        }

        if (typeOfConsent == 'gdpr' && isChecked) {
            this.subCustomerForm.gdpr_policy.setValue(true);
        } else if (typeOfConsent == 'gdpr' && !isChecked) {
            this.subCustomerForm.gdpr_policy.setValue(false);
        }
    }
}
