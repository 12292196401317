import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, WritableSignal } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { AccountService } from '../../../services/account/account.service';
import { Details } from 'shared_models/details';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardUser, LoggedInDashboardUser } from '@dashboard_models/dashboard-user';
import { lastValueFrom, Observable, Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import validUrl from 'valid-url';
import { HelperService } from 'src/app/services/helper/helper.service';
import { SnapshotAction } from '@angular/fire/compat/database';
import Stripe from 'stripe';
import * as Claims from '../../../../../shared_models/claims';
import { Roles } from '../../../../../shared_models/claims';
import { EmailGroups, InvitedUserDetails } from '../../../../../shared_models/invited-user';
import { DeleteModalService } from '../../../services/delete-modal/delete-modal.service';
import { DeleteModalOptions } from '../../../../../shared_models/deleteModalOptions';
import { TaxIdTypes } from '../../../constants/stripe_tax_id_types';
import { countries } from 'shared_data/countries';
import { TaxIdObject } from '@shared_models/details';
import moment from 'moment';
import { ResetPasswordService } from '../../../services/reset-password/reset-password.service';
import { LanguagePickerModalComponent } from './language-picker-modal/language-picker-modal.component';
import { CustomModalComponent } from '../../misc/custom-modal/custom-modal.component';
import { LoadingComponent } from '../../loading/loading.component';
import { ExternalAccountListComponent } from './external-account-list/external-account-list.component';
import { CustomToolTipComponent } from '../../misc/custom-tool-tip/custom-tool-tip.component';
import { AnimatedButtonComponent } from '../../misc/animated-button/animated-button.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { euCountries } from 'shared_data/euCountries';
import { QRCodeModule } from 'angularx-qrcode';
import { MultifactorQrEnablerComponent } from '@components/settings/account/multifactor-qr-enabler/multifactor-qr-enabler.component';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { ControlledUser } from '@shared_models/controlled-user';
import { AwTooltipComponent } from '../../../components/misc/aw-tooltip/aw-tooltip.component';

enum ActionWanted {
    UpdateEmail,
    UpdateAccount,
    DeleteAccount
}

interface FormatChecks {
    dash: boolean;
    space: boolean;
    dot: boolean;
    letters: string[];
    country: boolean;
}

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        AnimatedButtonComponent,
        FormsModule,
        ReactiveFormsModule,
        CustomToolTipComponent,
        NgFor,
        ExternalAccountListComponent,
        LoadingComponent,
        CustomModalComponent,
        LanguagePickerModalComponent,
        AsyncPipe,
        TranslateModule,
        QRCodeModule,
        MultifactorQrEnablerComponent,
        AwTooltipComponent
    ]
})
export class AccountComponent implements OnInit, OnDestroy {
    @ViewChild('reAuth', { static: false }) templateRef: TemplateRef<any>;
    role$: Observable<Claims.Roles> = this.authService.getRole;
    resultFile: any;
    licenses: any;
    formSubmitted = false;
    countries: string[] = [];
    accountForm: UntypedFormGroup;
    emailForm: UntypedFormGroup;
    roleSettingsForm: UntypedFormGroup;
    showSmallLoadingIndicator = false;
    showEmailLoadingIndicator = false;
    actionWanted: ActionWanted;
    formRdy = false;
    details: Details | InvitedUserDetails;
    kycPrompted = false;
    version: string = environment.VERSION;
    tos: boolean;
    oldEmail: string;
    onboardingRedirect: boolean;
    deletingAccount: boolean;
    userSettings: object;
    user: DashboardUser;
    mfaEnabled: WritableSignal<boolean> = this.authService.hasMfaEnabled;
    loggedInUser: LoggedInDashboardUser;
    customerDetailsSub: Subscription;
    formSub: Subscription;
    isSubCustomer: boolean;
    isOperator$: Observable<boolean>;
    noShare: boolean;
    controlledUserActive: boolean;
    businessType: Stripe.Account.BusinessType;
    isOwner = false;
    deleteModalOptions: DeleteModalOptions = {
        titleTranslationString: 'account.delete_account',
        descriptionTranslateString: 'account.are_you_sure',
        buttonTranslationString: 'account.delete_account'
    };
    VATNumberVerified: boolean;
    TAX_ID_TYPES: TaxIdObject[] = TaxIdTypes;
    initVerificationState: 'pre_pending' | 'pending' | 'verified' | 'failed';
    hasBookkeeper: boolean;
    vatCountry: string | undefined = undefined;
    initialVatNumber: string | undefined;
    initialVatType: TaxIdObject | undefined;
    constructor(
        public authService: AuthService,
        private customerService: CustomerService,
        protected modalService: NgbModal,
        private formBuilder: UntypedFormBuilder,
        private toast: ToastrService,
        private accountService: AccountService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private helperService: HelperService,
        private deleteModalService: DeleteModalService,
        private resetPasswordService: ResetPasswordService,
        private localStorageService: LocalStorageService
    ) {}

    ngOnDestroy() {
        this.customerDetailsSub ? this.customerDetailsSub.unsubscribe() : null;
        this.formSub ? this.formSub.unsubscribe() : null;
    }

    async ngOnInit() {
        if (this.route.snapshot.paramMap.get('param') === 'update_vat') {
            this.toast.info(this.translate.instant('account.update_vat'), this.translate.instant('misc.info'), { timeOut: 20000 });
        }

        this.user = this.helperService.getUser();
        this.userSettings = this.user.settings;
        this.loggedInUser = this.localStorageService.getItem('loggedInUser');
        this.isOwner = this.loggedInUser.role === Claims.Roles.owner;
        this.user.uid.includes('operated_by') ? (this.isSubCustomer = true) : null;
        this.isOperator$ = this.authService.isOperator;
        this.controlledUserActive = this.helperService.isUserControlled();
        this.onboardingRedirect = this.route.snapshot.paramMap.get('param') === 'onboarding' ? true : false;
        this.setupAccountForm();

        this.emailForm = this.formBuilder.group({
            email: new UntypedFormControl(null, [Validators.required])
        });

        this.roleSettingsForm = this.formBuilder.group({
            account_email: new UntypedFormControl(false),
            action_email: new UntypedFormControl(false),
            first_name: new UntypedFormControl(false, [Validators.required]),
            last_name: new UntypedFormControl(false, [Validators.required])
        });
        this.setSubscriptions();
    }

    onChanges(): void {
        this.formSub = this.accountForm.valueChanges.subscribe(val => {
            if (val.vat_number_not_provided) {
                this.vat_number.disable({ emitEvent: false });
                this.vat_number_type.disable({ emitEvent: false });
            } else if (!val.vat_number_not_provided && !this.formSubmitted) {
                this.vat_number.enable({ emitEvent: false });
                this.vat_number_type.enable({ emitEvent: false });
            } else if (val.vat_number_not_provided && (val.vat_number_type || val.vat_number) && !this.formSubmitted) {
                // when interacting with one of the fields set "pre_pending" to remove the "verified" label below the VAT number input field
                (this.details as Details)?.vat_number_details?.verification?.status ? ((this.details as Details).vat_number_details.verification.status = 'pre_pending') : null;

                if (
                    val.vat_number_type.tax_id === (this.details as Details)?.vat_number_details?.type &&
                    val.vat_number_type.country === (this.details as Details)?.vat_number_details?.country &&
                    val.vat_number === (this.details as Details)?.vat_number_details?.value
                ) {
                    (this.details as Details)?.vat_number_details?.verification?.status
                        ? ((this.details as Details).vat_number_details.verification.status = this.initVerificationState) // chance it back if no changes was made
                        : null;
                }
            }

            if (val.vat_number_type && val.vat_number_type.country) {
                if (this.vatCountry && this.vatCountry !== val.vat_number_type.country) {
                    this.vat_number.patchValue(null, { emitEvent: false });
                }
                this.vatCountry = val.vat_number_type.country;
            } else {
                this.vatCountry = undefined;
            }

            if (val.vat_number_type === this.initialVatType && val.vat_number === this.initialVatNumber) {
                this.vat_number.markAsPristine();
                this.vat_number_type.markAsPristine();
            }

            if (val.support_url) {
                //validate support url
                if (validUrl.isUri(val.support_url)) {
                    this.accountForm.get('support_url').setErrors(null);
                } else {
                    this.accountForm.get('support_url').setErrors({ formatIsWrong: true });
                }
            }
        });
    }

    setupAccountForm() {
        this.accountForm = this.formBuilder.group({
            accounting_email_check: new UntypedFormControl(true, []),
            action_email_check: new UntypedFormControl(false, []),
            vat_number_type: new UntypedFormControl(null, [Validators.required]),
            vat_number: new UntypedFormControl(null, [Validators.required, this.vatFormatValidator()]),
            vat_number_not_provided: new UntypedFormControl(false),
            support_url: new UntypedFormControl(null, [])
        });
    }

    setSubscriptions() {
        const permissionsSub = this.customerService
            .readSubCustomerPermission(this.loggedInUser.uid)
            .snapshotChanges()
            .subscribe(action => {
                permissionsSub.unsubscribe();
                if (action.payload.child('no_share').val()) this.noShare = true;
            });

        this.customerDetailsSub = this.customerService
            .readCustomerDetails(this.loggedInUser.uid)
            .snapshotChanges()
            .subscribe(async (detailsSnap: SnapshotAction<Details>) => {
                if (this.deletingAccount) return;

                if (detailsSnap.payload.exists() && !this.formSubmitted) {
                    await this.handleCustomerDetails(detailsSnap.payload.val());
                }
            });
    }

    async handleCustomerDetails(details: Details) {
        if (this.details) {
        }
        const skipFormPatching: boolean =
            this.details && (this.details as Details)?.onboarding_state?.external_account !== (details as Details)?.onboarding_state?.external_account
                ? true // if the state are different from init state
                : false;

        this.details = details; // then set or refresh the scope variable, else, we dont wanna touch them
        this.businessType = details.business_type;
        this.emailForm.patchValue({
            email: this.details.email
        });

        this.vatCountry = this.details.vat_number_details?.country;
        if (this.isOwner) {
            await this.getOwnerAccount(skipFormPatching);
        } else {
            this.getTeamMemberAccount();
        }
        this.formRdy = true;
        this.onChanges();
    }

    getTeamMemberAccount(): void {
        this.action_email.patchValue(this.details.email_groups.action_emails);
        this.first_name.patchValue((this.details as InvitedUserDetails).first_name);
        this.last_name.patchValue((this.details as InvitedUserDetails).last_name);
    }

    async getOwnerAccount(skipAccountFormPatch?: boolean): Promise<void> {
        await this.accountService.getAccount().then(accountResponse => {
            // <---- Needs some type of interface, to show what kind of data we can access.
            const account: Stripe.Account = accountResponse.account;
            if (!account) {
                this.toast.info(this.translate.instant('account.err_sign_in'), this.translate.instant('misc.info'));
                return;
            }

            !accountResponse.hasBookkeeper ? this.accounting_email_check.disable() : null;
            this.hasBookkeeper = accountResponse.hasBookkeeper;
            this.oldEmail = account.email;

            if (!skipAccountFormPatch && !this.formRdy) {
                this.accountForm.patchValue({
                    ...this.details,
                    ...{
                        vat_number: this.businessType === 'individual' ? undefined : (this.details as Details)?.vat_number_details?.value,
                        vat_number_type:
                            this.businessType === 'individual'
                                ? undefined
                                : TaxIdTypes.filter(obj => {
                                      // filters out the tax id object that it matches on type and country, always an array of size 1, thats why we take element 0 of the filter result
                                      return obj.tax_id === (this.details as Details)?.vat_number_details?.type && obj.country === (this.details as Details)?.vat_number_details?.country;
                                  })[0]
                    }
                });
                this.initialVatNumber = this.vat_number.value;
                this.initialVatType = this.vat_number_type.value;
            }

            if (this.businessType !== 'individual') {
                if (!(this.details as Details)?.vat_number_details)
                    (this.details as Details).vat_number_details = {
                        country: (this.details as Details).country,
                        created: moment().unix(),
                        type: '',
                        value: '',
                        verification: {
                            status: 'pre_pending'
                        }
                    };

                this.initVerificationState = (this.details as Details)?.vat_number_details?.verification?.status;

                if ((this.details as Details).vat_number_not_provided) {
                    this.vat_number.disable({ emitEvent: false });
                    this.vat_number_type.disable({ emitEvent: false });
                }
            }

            this.accounting_email_check.patchValue(this.details.email_groups.accounting_emails);
            this.action_email_check.patchValue(this.details.email_groups.action_emails);
        });
    }

    countryFromCode(info: TaxIdObject): string {
        const specs = countries[info.country];

        if (specs) {
            return specs.country;
        } else if (info.country === 'EU') {
            return 'Europe';
        } else if (info.country === 'XI') {
            return 'Northern Ireland';
        } else if (info.country === 'AD') {
            return 'Andorra';
        }
    }

    formatVATTypeId(vatTypeId: string, countryCode: string): string {
        if (vatTypeId.split('_')[0] === 'eu')
            // want the country code first on EU countries
            return `${countryCode} ${vatTypeId.split('_')[1]}`.toUpperCase().replaceAll('_', ' ');

        return vatTypeId.toUpperCase().replaceAll('_', ' ');
    }

    async updateAccountSettings(accountForm: UntypedFormGroup): Promise<void> {
        this.isOwner ? await this.updateAccount(accountForm) : await this.updateEmailSettingsForTeamMember();
    }

    async updateEmailSettingsForTeamMember() {
        this.showSmallLoadingIndicator = true;
        this.formSubmitted = true;
        if (this.roleSettingsForm.valid) {
            const emailSettings: Record<EmailGroups, boolean> = {
                [EmailGroups.action_emails]: this.action_email.value,
                [EmailGroups.accounting_emails]: this.account_email.value,
                [EmailGroups.all]: this.account_email.value && this.action_email.value
            };

            const updatedDetails: InvitedUserDetails = {
                ...(this.details as InvitedUserDetails),
                email_groups: emailSettings,
                first_name: this.first_name.value,
                last_name: this.last_name.value
            };
            await this.accountService.updateTeamMemberEmailSettings(this.loggedInUser.uid, updatedDetails).then(() => {
                this.updateNameInLocalStorage(updatedDetails.first_name, updatedDetails.last_name);
                this.formSubmitted = false;
                this.toast.success(this.translate.instant('account.success_update'), this.translate.instant('misc.success'));
                this.showSmallLoadingIndicator = false;
            });
        }
        this.showSmallLoadingIndicator = false;
    }

    updateNameInLocalStorage(first: string, last: string): void {
        const tempStore: LoggedInDashboardUser = this.localStorageService.getItem('loggedInUser');
        const loggedInUser: LoggedInDashboardUser = {
            ...tempStore,
            name: first && last ? `${first} ${last}` : tempStore.email
        };
        this.localStorageService.setItem('loggedInUser', loggedInUser);
    }

    async updateAccount(accountForm: UntypedFormGroup) {
        this.actionWanted = ActionWanted.UpdateAccount;
        this.showSmallLoadingIndicator = true;
        this.formSubmitted = true;

        const accountFormCopy = { ...accountForm.value }; // creating a copy
        for (const controlKey of Object.keys(accountForm.controls)) {
            if (
                accountForm.controls[controlKey].pristine && // except VAT number, that should always be required, except if business type is "individual".
                !((controlKey === 'vat_number' || controlKey === 'vat_number_type') && (this.details as Details).business_type !== 'individual')
            ) {
                // removes all validation from each field that has not been modified.
                this[controlKey].setValidators(null);
                this[controlKey].setErrors(null);
                delete accountFormCopy[controlKey]; // would not ship values if they are not changed.
            }
        }

        console.log('update', accountForm);

        if (accountForm.valid) {
            // Disable inpuits
            this.accountForm.disable();

            // Updating values in the right format
            const shippedValues = {
                ...accountFormCopy,
                ...{
                    action_email_check: this.action_email_check.value,
                    accounting_email_check: this.accounting_email_check.value,
                    country: this.user.settings.country,
                    vatCountry: this.vatCountry
                }
            };

            if (this.businessType !== 'individual') {
                shippedValues.vat_number_not_provided = this.vat_number_not_provided.value;
                shippedValues.vat_number ? (shippedValues.vat_number_type = accountFormCopy.vat_number_type.tax_id) : null;

                if (this.vat_number_not_provided.value) {
                    delete shippedValues.vat_number;
                    this.vat_number.patchValue(undefined);
                    this.vat_number_type.patchValue(undefined);
                } else if (
                    !(this.details as Details)?.vat_number_details ||
                    this.vat_number_type?.value?.tax_id !== (this.details as Details)?.vat_number_details?.type ||
                    this.vat_number_type?.value?.country !== (this.details as Details)?.vat_number_details?.country ||
                    this.vat_number.value !== (this.details as Details)?.vat_number_details?.value
                ) {
                    // only switch it to pending if there is a change, else let it be.
                    (this.details as Details)?.vat_number_details?.verification?.status ? ((this.details as Details).vat_number_details.verification.status = 'pending') : null;
                }
            }

            await this.accountService
                .updateAccount(shippedValues)
                .then(async () => {
                    this.setSubscriptions();

                    if (this.onboardingRedirect) {
                        setTimeout(() => {
                            this.toast.success(this.translate.instant('account.success_update'), this.translate.instant('misc.success'));
                            this.router.navigate([`/overview`]);
                            this.formSubmitted = false;
                            this.showSmallLoadingIndicator = false;
                        }, 1500);
                    } else {
                        this.toast.success(this.translate.instant('account.success_update'), this.translate.instant('misc.success'));
                        this.formSubmitted = false;
                        this.showSmallLoadingIndicator = false;
                        this.initialVatNumber = this.vat_number.value;
                        this.initialVatType = this.vat_number_type.value;
                        this.accountForm.enable();
                    }
                })
                .catch((err: any) => {
                    console.log(err?.error?.error);

                    if (err?.error?.error === 'vat_country_mismatch') {
                        (this.details as Details).vat_number_details.verification.status = 'failed';
                        this.toast.warning(this.translate.instant('account.vat.errors.vat_country_mismatch'), this.translate.instant('misc.attention'), { timeOut: 12000 });
                    }

                    if (err?.error?.error === 'vat_id_not_valid') {
                        (this.details as Details).vat_number_details.verification.status = 'failed';
                        this.toast.warning(this.translate.instant('account.vat_number_invalid'), this.translate.instant('misc.attention'), { timeOut: 12000 });
                    } else {
                        this.toast.warning(this.translate.instant(`account.could_not_update`), this.translate.instant('misc.attention'), { timeOut: 7000 });
                    }
                    this.showSmallLoadingIndicator = false;
                });
        } else {
            this.showSmallLoadingIndicator = false;
        }
    }

    async updateEmail(emailForm: UntypedFormGroup) {
        this.actionWanted = ActionWanted.UpdateEmail;
        if (this.details.email === this.email.value) {
            this.helperService.defaultHtmlToast('', this.translate.instant('account.email_is_the_same'), 'Info');
            return;
        }

        if (emailForm.valid) {
            sessionStorage.setItem('email_update_flag', 'true');
            this.showEmailLoadingIndicator = true;
            await this.accountService
                .updateEmail(this.details.email, this.email.value)
                .then(() => {
                    this.modalService.dismissAll();
                })
                .catch(() => {
                    sessionStorage.removeItem('email_update_flag');
                    this.helperService.defaultHtmlToast('', this.translate.instant('account.err_update_email'), 'Info');
                });
            this.showEmailLoadingIndicator = false;
        }
    }

    isEuCountry(country: string | undefined): boolean {
        if (!country) {
            return;
        }

        return euCountries.includes(country);
    }

    updateMailInLocalStorage(email: string) {
        const loggedInUser: LoggedInDashboardUser = this.localStorageService.getItem('loggedInUser');
        if (loggedInUser.name === loggedInUser.email) {
            const updatedLoggedInUser: LoggedInDashboardUser = { ...loggedInUser, email: email, name: email };
            this.localStorageService.setItem('loggedInUser', updatedLoggedInUser);
        } else {
            const updatedLoggedInUser: LoggedInDashboardUser = { ...loggedInUser, email: email };
            this.localStorageService.setItem('loggedInUser', updatedLoggedInUser);
        }

        if (this.user.uid === loggedInUser.uid) {
            const updatedUser: DashboardUser | ControlledUser = { ...this.user, email: email };
            if (this.controlledUserActive) {
                this.localStorageService.setItem('controlled_user', updatedUser as unknown as ControlledUser);
            } else {
                this.localStorageService.setItem('user', updatedUser);
            }
        }
    }

    async deleteAccount() {
        this.modalService.dismissAll();
        this.actionWanted = ActionWanted.DeleteAccount;
        const isConfirmed = await this.deleteModalService.userHasConfirmedDeletion(this.deleteModalOptions);
        if (isConfirmed) {
            this.deletingAccount = true;
            this.accountService.deleteAccount(true, this.user.uid).catch(async err => {
                if (err.status === 400) {
                    this.toast.warning(err.error.error.message, this.translate.instant('misc.attention'), { timeOut: 10 * 1000 });
                } else if (err.code === 'auth/requires-recent-login') {
                    const text: string = this.translate.instant('account.to_delete_account');
                    await this.authService.handleReauthentication(text);
                    await this.deleteAccount();
                } else {
                    this.toast.warning(err.message, this.translate.instant('misc.attention'));
                }
                this.deletingAccount = false;
            });
        }
    }

    resetPassword() {
        if (!this.details.email) {
            alert(this.translate.instant('account.type_email'));
        }
        this.resetPasswordService.requestResetPasswordEmail(this.details.email);
        this.modalService.dismissAll();
        this.toast.success(this.translate.instant('account.update_pw_desc'), this.translate.instant('account.update_pw_title'));
    }

    licensesModal(modal: any) {
        lastValueFrom(this.http.get(`/licenses.json`, { responseType: 'json' }))
            .then(resultFile => {
                this.resultFile = resultFile;
                this.licenses = Object.keys(resultFile);
                this.openModal(modal, 'licenses');
            })
            .catch(err => {});
    }

    async openModal(modal: TemplateRef<any>, modalName?: string) {
        const open = async (modal: TemplateRef<any>, options?: NgbModalOptions): Promise<any> => {
            const modalPromise: Promise<any> = this.modalService
                .open(modal, {
                    ...{
                        ariaLabelledBy: 'modal-basic-title'
                    },
                    ...(options ? options : {})
                })
                .result.catch(() => {
                    // empty for now, should be here to avoid an uncaught promise on modal cancel action.
                });

            return modalPromise;
        };

        switch (modalName) {
            case 'licenses':
                const options: NgbModalOptions = {
                    size: 'xl',
                    modalDialogClass: 'modal-xl',
                    scrollable: true
                };
                await open(modal, options);
                break;

            case 'newBank':
                if (this.formRdy) await open(modal);
                break;

            default:
                await open(modal);
                break;
        }
    }

    vatFormatValidator(): ValidatorFn {
        return (control: UntypedFormControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            const taxIdObject: TaxIdObject = this.vat_number_type.value;
            // For initial load. At this point vatValCountry is not set, so we need to return null
            if (!taxIdObject) {
                return null;
            }

            const checkResult = this.formatChecker(control, taxIdObject);

            if (checkResult) {
                return checkResult.errorObject;
            }

            return null;
        };
    }

    assistVatNumber(event: InputEvent) {
        if (this.vat_number_type.value.country !== 'US') {
            return;
        }
        const input = event.target as HTMLInputElement;
        const format = this.formatChecker(this.vat_number as FormControl, this.vat_number_type.value);

        if (!format) {
            return;
        }

        const formatDetails = format.formatError;
        const vatNumber: string = input.value;
        const keyToCheck = formatDetails.indentsToCheck[formatDetails.format].key;
        const indices = formatDetails.indentsToCheck[formatDetails.format].indices;

        if (indices.length > 0 && vatNumber.length > indices[0] && vatNumber[indices[0]] !== keyToCheck) {
            const value = vatNumber.slice(0, indices[0]) + keyToCheck + vatNumber.slice(indices[0]);
            this.vat_number.patchValue(value, { emitEvent: false });
        }
    }

    formatChecker(
        control: FormControl,
        taxIdObject: TaxIdObject
    ): { errorObject: { formatIsWrong: { value: string } }; formatError: { format: string; indentsToCheck: Record<string, { key: string; indices }>; value: FormControl['value'] } } {
        const formatsToCheck: FormatChecks = {
            country: taxIdObject.example.includes(taxIdObject.country),
            letters: taxIdObject.example.match(/[a-z]/gi),
            dash: taxIdObject.example.includes('-'),
            space: taxIdObject.example.includes(' '),
            dot: taxIdObject.example.includes('.')
        };

        const indentsToCheck: Record<string, { key: string; indices }> = {
            dash: { key: '-', indices: [] },
            space: { key: ' ', indices: [] },
            dot: { key: '.', indices: [] }
        };

        for (const indent in indentsToCheck) {
            if (formatsToCheck[indent]) {
                const str = taxIdObject.example;
                const indices = [];

                for (let i = 0; i < str.length; i++) {
                    if (str[i] === indentsToCheck[indent].key) {
                        indices.push(i);
                    }
                }

                indentsToCheck[indent].indices = indices;
            }
        }

        // Check if the vat number contains - or space or .
        const controlFormatCheck: FormatChecks = {
            country: control.value.includes(taxIdObject.country),
            letters: control.value.match(/[a-z]/gi),
            dash: control.value.includes('-'),
            space: control.value.includes(' '),
            dot: control.value.includes('.')
        };

        for (const indent in indentsToCheck) {
            if (controlFormatCheck[indent] !== formatsToCheck[indent]) {
                const errorObject = { formatIsWrong: { value: 'account.vat.errors.' + indent } };
                const formatError = { format: indent, indentsToCheck, value: control.value };
                return { errorObject, formatError };
            }

            // Check if the vat number contains the dash, space or dots in the right indices

            for (const index of indentsToCheck[indent].indices) {
                if (control.value[index] !== indentsToCheck[indent].key) {
                    const errorObject = { formatIsWrong: { value: 'account.vat.errors.' + indent } };
                    const formatError = { format: indent, indentsToCheck, value: control.value };
                    return { errorObject, formatError };
                }

                if (!control.value[index + 1]) {
                    const errorObject = { formatIsWrong: { value: 'account.vat.errors.missing_numbers' } };
                    const formatError = { format: indent, indentsToCheck, value: control.value };
                    return { errorObject, formatError };
                }
            }
        }

        return null;
    }

    get email() {
        return this.emailForm.get('email');
    }

    get vat_number_type() {
        return this.accountForm.get('vat_number_type');
    }

    get vat_number() {
        return this.accountForm.get('vat_number');
    }

    get vat_number_not_provided() {
        return this.accountForm.get('vat_number_not_provided');
    }

    get support_url() {
        return this.accountForm.get('support_url');
    }

    get accounting_emails() {
        return this.accountForm.get('accounting_emails');
    }

    get accounting_email_check() {
        return this.accountForm.get('accounting_email_check');
    }

    get action_email_check() {
        return this.accountForm.get('action_email_check');
    }

    get account_email() {
        return this.roleSettingsForm.get('account_email');
    }

    get action_email() {
        return this.roleSettingsForm.get('action_email');
    }

    get first_name() {
        return this.roleSettingsForm.get('first_name');
    }

    get last_name() {
        return this.roleSettingsForm.get('last_name');
    }

    protected readonly Roles = Roles;
}
